



















import './styles/AllUsers.scss'
import './styles/AllUsersAdaptive.scss'
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IAllUsersUser, IUserApp } from '@store/modules/user-projects/Interfaces';

const NSUserProjects = namespace('storeUserProjects');

@Component({
   name: 'AllUsers',

   components: {
       BaseListItemShell: () => import('@components/BaseListItemShell'),
       UserProjectRow: () => import('./UserProjectRow.vue'),
   },
})
export default class AllUsers extends Vue {

    @Prop({default: new Map()}) projectsByUsers!: Map<IUserApp, IAllUsersUser> ;

    toggleProjectSelection(user: IAllUsersUser) {
        user.selected = !user.selected;
    }

    isOnTop(userIndex: number) {
        return userIndex === 0;
    }
}
